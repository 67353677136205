<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <b-row class="match-height">
          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-1 white">
                <div class="p-1 text-center">
                  <span><i class="fal fa-users" /> สมาชิก</span>
                  <h4 class="text-white">
                    เปิดยูสวันนี้ {{ Commas(DataShow.usernewdays.total) }}
                  </h4>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>ทั้งหมด</small>
                  <small>{{ Commas(DataShow.totaluser.total) }} User</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-2 white">
                <div class="p-1 text-center">
                  <span><i class="fal fa-donate" /> จ่ายโบนัสวันนี้</span>
                  <h4 class="text-white">
                    {{ Commas(DataShow.bonus.reduce((prev, curr) => prev + Number(curr.bonus), 0)) }} บาท
                  </h4>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>อื่นๆ</small>
                  <small>0.00 Bath</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-3 white">
                <div class="p-1 text-center">
                  <span><i class="fal fa-hand-holding-usd" /> เงินถอนวันนี้</span>
                  <h4 class="text-white">
                    AUTO: {{ Commas(DataShow.withdraws.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} / Manual: {{ Commas(DataShow.withdraws_manual.reduce((prev, curr) => prev + Number(curr.amount), 0)) }}
                  </h4>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>รวม</small>
                  <small>{{ Commas(DataShow.withdraws.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.withdraws_manual.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} Bath</small>
                </div>
              </div>

            </div>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-blue">
                <div class="p-1 text-center">
                  <span><i class="fal fa-users" /> สมัครและเติมวันนี้</span>
                  <h4 class="text-white">
                    {{ Commas(DataShow.regisanddep) }} คน
                  </h4>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>ข้อมูลตามช่วงเวลา</small>
                  <small>-</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-pink">
                <div class="p-1 text-center">
                  <span><i class="fa-brands" /> Active วันนี้</span>
                  <h4 class="text-white">
                    {{ Commas(DataShow.active) }}
                  </h4>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>ข้อมูลตามช่วงเวลา</small>
                  <small>-</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-yellow">
                <div class="p-1 text-center">
                  <span><i class="fal fa-hand-holding-usd" /> เติมเงินครั้งแรก</span>
                  <h4 class="text-white">
                    {{ Commas(DataShow.depfirst) }} คน
                  </h4>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>ข้อมูลตามช่วงเวลา</small>
                  <small>-</small>
                </div>
              </div>

            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ดูภาพรวมของระบบตามวัน เวลา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group">
              <label for="username">เลือกธนาคาร</label>
              <b-form-select
                v-model="search_from"
                :options="fromOPs"
                placeholder="เลือกธนาคาร"
              />
            </div>
          </div> -->
          <div class="col-md-2">
            <label for="dateEnd" />
            <button
              class="btn btn-gradient-dark btn-block"
              style="margin-top: 3px"
              @click="getData()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
          <div class="col-md-2">
            <label for="dateEnd" />
            <button
              class="btn btn-gradient-success btn-block"
              style="margin-top: 3px"
              @click="ExportDep()"
            >
              Export รายการฝาก
            </button>
          </div>
          <div class="col-md-2">
            <label for="dateEnd" />
            <button
              class="btn btn-gradient-danger btn-block"
              style="margin-top: 3px"
              @click="ExportWith()"
            >
              Export รายการถอน
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-row class="match-height">
          <b-col
            md="12"
            cols="12"
          >
            <h4><i class="far fa-money-check-edit-alt" /> การเติมเครดิต</h4>
          </b-col>
          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-scb white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcon/scb.png"
                      alt="bank"
                      class="bank-icon"
                    > ธนาคารไทยพาณิชย์
                  </span>
                  <h5 class="text-white">
                    Slip : {{ Commas(DataShow.scb.auto_scb.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                  <h5 class="text-white">
                    Manual : {{ Commas(DataShow.scb.manual_scb.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-kbank white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcons/kbank.svg"
                      alt="bank"
                      class="bank-icon"
                    > ธนาคารกสิกรไทย
                  </span>
                  <h5 class="text-white">
                    Slip : {{ Commas(DataShow.kbank.auto_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                  <h5 class="text-white">
                    Manual : {{ Commas(DataShow.kbank.manual_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-true white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcon/true.svg"
                      alt="bank"
                      class="bank-icon"
                    > ทรูมันนี่ วอลเล็ท
                  </span>
                  <h5 class="text-white">
                    Slip : {{ Commas(DataShow.truewallet.auto_true.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                  <h5 class="text-white">
                    Manual : {{ Commas(DataShow.truewallet.manual_true.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-total white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcons/bank.png"
                      alt="bank"
                      class="bank-icon"
                    > ยอดรวม
                  </span>
                  <h5 class="text-white">
                    Slip : {{ Commas(DataShow.scb.auto_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.auto_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.auto_true.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                  <h5 class="text-white">
                    Manual : {{ Commas(DataShow.scb.manual_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.manual_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.manual_true.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="12"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-1 white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcons/bank.png"
                      alt="bank"
                      class="bank-icon"
                    > สรุปยอด
                  </span>
                  <h5 class="text-white">
                    ส่วนต่างฝาก-ถอนเครดิต : {{ Commas(DataShow.scb.auto_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.auto_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.auto_true.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.scb.manual_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.manual_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.manual_true.reduce((prev, curr) => prev + Number(curr.amount), 0) - (DataShow.withdraws.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.withdraws_manual.reduce((prev, curr) => prev + Number(curr.amount), 0))) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <b-row class="match-height">
          <b-col
            md="12"
            cols="12"
          >
            <h4><i class="far fa-envelope-open-dollar" /> เงินเข้าระบบ (รายการจากธนาคาร)</h4>
          </b-col>
          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-scb white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcon/scb.png"
                      alt="bank"
                      class="bank-icon"
                    > ธนาคารไทยพาณิชย์
                  </span>
                  <h5 class="text-white">
                    {{ Commas(DataShow.scb.auto_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.scb.manual_scb.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-kbank white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcons/kbank.svg"
                      alt="bank"
                      class="bank-icon"
                    > ธนาคารกสิกรไทย
                  </span>
                  <h5 class="text-white">
                    {{ Commas(DataShow.kbank.auto_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.manual_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-true white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcon/true.svg"
                      alt="bank"
                      class="bank-icon"
                    > ทรูมันนี่ วอลเล็ท
                  </span>
                  <h5 class="text-white">
                    {{ Commas(DataShow.truewallet.auto_true.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.manual_true.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>

          <b-col
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-total white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcons/bank.png"
                      alt="bank"
                      class="bank-icon"
                    > ยอดรวม
                  </span>
                  <h5 class="text-white">
                    {{ Commas(DataShow.scb.auto_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.auto_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.auto_true.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.scb.manual_scb.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.kbank.manual_kbank.reduce((prev, curr) => prev + Number(curr.amount), 0) + DataShow.truewallet.manual_true.reduce((prev, curr) => prev + Number(curr.amount), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small><i class="fas fa-arrow-up" /> - %</small> -->
                  <small>ข้อมูลตามช่วงเวลา</small>
                </div>
              </div>

            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
import {
  BRow,
  BCol,
  VBTooltip,
  // BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody,
  VBToggle, BOverlay, BIconController,
  // BBadge, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    // vSelect,
    // BModal,
    // BCard,
    // BTable,
    // BAvatar,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BFormInput,
    // BButton,
    // BCardBody,
    BOverlay,
    BIconController,
    // BBadge,
    // BFormRadio,
    // flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type: null,
      search_val: null,
      show: false,
      show2: false,
      Selected: null,
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      DataShow: {
        usernewdays: { total: 0 },
        totaluser: { total: 0 },
        bonus: [],
        withdraws: [],
        withdraws_manual: [],
        scb: {
          auto_scb: [],
          manual_scb: [],
        },
        kbank: {
          auto_kbank: [],
          manual_kbank: [],
        },
        truewallet: {
          auto_true: [],
          manual_true: [],
        },
        regisanddep: 0,
        depfirst: 0,
        active: 0,
        ssss: 0,
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      // this.getData()
    }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    this.getData()
  },
  methods: {
    ExportDep() {
      const Time = moment().tz('Asia/Bangkok').format('HH')
      if (Time === '23' || Time === '00' || Time === '01') {
        this.show = true
        this.$http
          .get(`/export/ExportDep/${this.dateStart}/${this.dateEnd}`, { responseType: 'blob' })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.show = false
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(
              new Blob([response.data]),
            )
            // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
            link.setAttribute('download', 'Report Depsit.xlsx')
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => console.log(error))
      // window.open(
      //   `/export/ExportDep/${this.dateStart}/${this.dateEnd}`,
      // )
      } else {
        this.SwalError('สามารถ Export ได้ในช่วงเวลา 23.00 - 01.00')
      }
    },
    ExportWith() {
      const Time = moment().tz('Asia/Bangkok').format('HH')
      if (Time === '23' || Time === '00' || Time === '01') {
        this.show = true
        this.$http
          .get(`/export/ExportWith/${this.dateStart}/${this.dateEnd}`, { responseType: 'blob' })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.show = false
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(
              new Blob([response.data]),
            )
            // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
            link.setAttribute('download', 'Report Withdraw.xlsx')
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => console.log(error))
      // window.open(
      //   `/export/ExportDep/${this.dateStart}/${this.dateEnd}`,
      // )
      } else {
        this.SwalError('สามารถ Export ได้ในช่วงเวลา 23.00 - 01.00')
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getData() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/dashboard/GetDepAndWithNewDashboard', params)
        .then(response => {
          this.show = false
          this.DataShow = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
  .bank-icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
